<template>
  <v-dialog :value="dialog" persistent max-width="1000" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Create Workspace </v-card-title>
      <v-row class="CreateWorkspace__header">
        Name
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        <v-col>
          <v-text-field
              class="TextArea"
              outlined
              dense
              :placeholder="'Name'"
              hide-details="auto"
              novalidate
              :rows="5"
              v-model="name"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="CreateWorkspace__header">
        Description
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        <v-col>
          <v-textarea
              class="TextArea"
              outlined
              dense
              :placeholder="'Description'"
              hide-details="auto"
              novalidate
              :rows="5"
              v-model="description"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row class="CreateWorkspace__header">
        <h4>Chat Model</h4>
      </v-row>
      <v-row class="CreateWorkspace__toolSet_dropdown">
        <v-col>
          <v-autocomplete
              class="mb-3"
              style="width: 250px"
              outlined
              dense
              label="AI Model"
              :items="models"
              v-model="selectedModel"
              item-text="label"
              item-value="value"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="CreateWorkspace__header">
        <h4>Tools</h4>
        <v-btn style="" icon @click.stop="isToolSelectDialog = true">
          <v-icon small> mdi-pencil-outline </v-icon>
        </v-btn>
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        {{ this.toolSets.map((tool) => tool.name).join(', ') }}
      </v-row>
      <v-row class="CreateWorkspace__header">
        <h4>System Prompt</h4>
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        <v-col>
          <v-textarea
              class="TextArea"
              outlined
              dense
              :placeholder="'System Prompt'"
              hide-details="auto"
              novalidate
              :rows="5"
              v-model="systemPromptText"
          >
          </v-textarea>
        </v-col>
        <v-col>
          <v-row>
            <v-autocomplete
                class="mb-3 mt-3"
                outlined
                dense
                clearable
                label="System Prompts"
                :items="systemPrompts"
                v-model="selectedSystemPrompt"
                item-text="name"
                item-value="id"
                @change="(value) => updateSelectedSystemPrompt(value)"
                :append-outer-icon="systemPromptLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
                @click:append-outer="this.getSystemPrompts"
            ></v-autocomplete>
          </v-row>
          <v-row>
            <v-btn @click.stop="saveSystemPrompt()" class="CreateWorkspace__sendButton" :loading="updateSystemPromptLoading">
              Save
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="CreateWorkspace__header">
        <h4>Max Tokens</h4>
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        <div class="maxTokens">
          <v-slider
              v-model="maxTokens"
              :min="1000"
              :max="15000"
          ></v-slider>
          <span>
             {{ maxTokens }}
          </span>
        </div>
      </v-row>
      <v-row class="CreateWorkspace__header">
        <h4>Number of latest included messages</h4>
      </v-row>
      <v-row class="CreateWorkspace__historyLimit">
        <div class="maxTokens">
          <v-slider
              v-model="historyLimit"
              :min="0"
              :max="50"
              :disabled="includeAllHistory"
          ></v-slider>
          <span>
             {{ historyLimit }}
          </span>
        </div>
        <v-checkbox v-if="isShAdmin()" label="Include All" v-model="includeAllHistory">
        </v-checkbox>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Close </v-btn>
        <v-btn text @click="createNewWorkspace" color="primary" :loading="createWorkspaceLoading"> Create </v-btn>
      </v-card-actions>
    </v-card>
    <tools-select :dialog="isToolSelectDialog" :toolSets="toolSets" @close="isToolSelectDialog = false" @toolsChanged="(data) => { toolSets = data}"/>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ToolsSelect from "@/views/ai-workspaces/create-workspace/tools-select/tools-select.vue";
import lodashGet from "lodash/get";
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
} = createNamespacedHelpers('AIChat');

const { mapActions: workspaceActions, mapGetters: workspaceGetter } = createNamespacedHelpers('workspace');
const { mapGetters: customerGetters } =
    createNamespacedHelpers('customer');

export default {
  name: 'CreateWorkspace',
  components: {
    ToolsSelect,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxTokens: 2000,
      systemPromptText: '',
      selectedSystemPrompt: null,
      includeAllHistory: false,
      toolSets: [],
      isToolSelectDialog: false,
      name: '',
      description: '',
      historyLimit: 15,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        },
        {
          label: 'Claude V2',
          value: 'anthropic.claude-v2:1'
        }
      ],
      selectedModel: 'anthropic.claude-3-haiku-20240307-v1:0',
    };
  },
  computed: {
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
      updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING'
    }),
    ...workspaceGetter({
      createWorkspaceLoading: 'WORKSPACE_UPDATE_LOADING'
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      customers: 'CUSTOMERS',
      selected_customer: 'SELECTED_CUSTOMER',
      featureFlags: 'FEATURE_FLAGS',
    }),
  },
  methods: {
    ...AIChatActions(['getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt']),
    ...workspaceActions(['createWorkspace']),
    toolsChanged(data) {
      this.toolSets = data;
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    async saveSystemPrompt() {
      if(this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
        return;
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      })
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === value);
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    async createNewWorkspace() {
        const workspace = await this.createWorkspace({
          toolSetIds: this.toolSets.map(tool => tool.id),
          name: this.name,
          description: this.description,
          model: this.selectedModel,
          systemPromptId: this.selectedSystemPrompt?.id,
          maxTokens: this.maxTokens,
          historyLimit: this.includeAllHistory ?  null : this.historyLimit,
        })
        this.$router.push({ path: `workspaces/${workspace.id}`})
    }
  },
  async created() {
    this.getSystemPrompts();
  }
};
</script>

<style lang="scss" scoped>
@import './create-workspace';
</style>
